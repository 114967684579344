import AOS from "aos";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavScrollTop from "./components/nav-scroll-top";
import RouteChangeTracker from "./components/analytics";
// CSS File Here
import "swiper/components/pagination/pagination.scss";
import "swiper/swiper.scss";
import "./assets/css/animate.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/elegantIcons.css";
import "./assets/css/icofont.css";
import "./assets/scss/style.scss";
import "aos/dist/aos.css";
import "lightgallery.js/dist/css/lightgallery.css";

const AboutPage = React.lazy(() => import("./pages/about"));
const Contact = React.lazy(() => import("./pages/contact"));
const HomePage = React.lazy(() => import("./pages/index"));
const Portfolio = React.lazy(() => import("./pages/portfolio"));
const VideoGallery = React.lazy(() => import("./pages/video-gallery"));
const PortfolioDetails = React.lazy(() =>
    import("./templates/portfolio-details")
);

const App = () => {
    const TRACKING_ID = "G-9T0Y5RR433"; //TRACKING_ID
    useEffect(() => {
        AOS.init({
            duration: 1000,
            mirror: true,
            easing: "ease",
        });
        AOS.refresh();
        ReactGA.initialize({
            trackingId: TRACKING_ID,
            debug: true,
            gaOptions: {
                cookieDomain: "none",
            },
        });
    }, []);
    return (
        <React.Suspense fallback={null}>
            <Router>
                <RouteChangeTracker />
                <NavScrollTop>
                    <Switch>
                        <Route
                            path={`${process.env.PUBLIC_URL + "/"}`}
                            exact
                            component={HomePage}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/about"}`}
                            component={AboutPage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/photo-gallery"
                            }`}
                            component={Portfolio}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL +
                                "/:category/:projectName"
                            }`}
                            component={PortfolioDetails}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/video-gallery"
                            }`}
                            component={VideoGallery}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/contact"}`}
                            component={Contact}
                        />
                    </Switch>
                </NavScrollTop>
            </Router>
        </React.Suspense>
    );
};

export default App;
